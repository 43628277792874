import { Container, Pagination } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/Card/Card";
import { useGetAllCandidateCardsQuery } from "../../../../rq/hooks/useQueries";
import { CardsWrapper, Description, MainWrapper, Title } from "../styles";

export default function StudentsCards() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data, refetch } = useGetAllCandidateCardsQuery(page, null, {
    keepPreviousData: true,
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Container>
      <MainWrapper>
        <Title>{t("home.students_cards.title")}</Title>
        <Description>{t("home.students_cards.description")}</Description>
        <CardsWrapper>
          {data?.data?.map((card: any, i: any) => (
            <Card
              key={i}
              profilePicture={card.profilePicture}
              candidateUserId={card.candidateUserId}
              candidateId={card.candidateId}
              professions={card.profession}
              businessUserId={card.businessUserId}
              businessId={card.businessId}
              name={card.name}
              lastName={card.lastName}
              country={card.country}
              video={card.video}
              videoViews={card.videoViews}
              likes={card.likes}
              saves={card.saves}
              isMyFavorite={card.isMyFavorite}
              likedFromMe={card.likedFromMe}
              refetch={refetch}
              queryKeyString={["get-all-candidate-cards"]}
              videoId={card.videoId}
              boughtFromCurrentUser={card.boughtFromCurrentUser}
              digitalBook={card.digitalBook}
              hasCv={card.hasCv}
              cvPath={card.cvPath}
              pageNumber={data.metaData.pageNumber}
              style={{ border: "1px solid #D0D0D0", borderRadius: "20px" }}
            />
          ))}
        </CardsWrapper>
        <Pagination
          count={data?.metaData?.pageCount}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          hidePrevButton
          hideNextButton
          sx={{ alignSelf: "center" }}
        />
      </MainWrapper>
    </Container>
  );
}
