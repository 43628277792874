import * as yup from 'yup';
const FILE_SIZE = 150000000;

export const AddAccomplishmentSchema = (t: any) =>
  yup.object({
    accomplishmentTypeId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    description: yup.string().required(t('formErrors.required')),
    startYear: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    startMonth: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
  });

export const AddEducationSchema = (t: any) =>
  yup.object({
    educationId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    fieldOfStudyId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    description: yup.string().required(t('formErrors.required')),
    startYear: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    startMonth: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
  });

export const AddCertificationSchema = (t: any) =>
  yup.object({
    name: yup.string().required(t('formErrors.required')),
    organisation: yup.string().required(t('formErrors.required')),
    description: yup.string().required(t('formErrors.required')),
    startYear: yup
      .number()
      .transform((value: any) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    startMonth: yup
      .number()
      .transform((value: any) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
  });

export const AddExperienceSchema = (t: any) =>
  yup.object({
    enterpriseName: yup.string().required(t('formErrors.required')),
    position: yup.string().required(t('formErrors.required')),
    location: yup.string().required(t('formErrors.required')),
    startYear: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
    startMonth: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('formErrors.required')),
  });

export const EditBusinessProfileFormSchema = (t: any) =>
  yup.object({
    companyName: yup.string().required(t('formErrors.required')),
    companyVat: yup.string().required(t('formErrors.required')),
    companyAddress: yup.string().required(t('formErrors.required')),
    countryId: yup.number().nullable(),
    postalCode: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)),
    description: yup.string().nullable(),
  });

export const VideoPresentationSchema = (t: any) =>
  yup.object({
    file: yup.mixed().test('fileSize', t('formErrors.large_file'), (value) => {
      return value && value?.size <= FILE_SIZE;
    }),
  });
