import React, { useEffect, useState } from "react";
import { MainWrapper } from "../../styles";
import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputField } from "../../../InputField/InputField";
import ReactQuill from "react-quill";
import {
  useEditJobDraftMutation,
  useGetJobAdDetailsQuery,
} from "../../../../../rq/hooks/useJob.mutation";
import { useNavigate, useParams } from "react-router-dom";
import { typeOfEmployments } from "../../../../../static";
import CircularIndeterminate from "../../../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  AddJobOfferDto,
  StateType,
} from "../../../../../interfaces/JobOffers.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddJobSchema } from "../../../../../validators/JobOffers.schema";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

const EditDraft = () => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<AddJobOfferDto>({
    resolver: yupResolver(AddJobSchema(t)),
  });

  const [checkboxValue, setCheckboxValue] = useState({
    cdd: false,
    internship: false,
    jobStudent: false,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { data: jobDetails, isLoading: jobDetailsLoading } =
    useGetJobAdDetailsQuery(Number(id));
  const { mutate: editDraft, isLoading: editDraftLoading } =
    useEditJobDraftMutation(Number(id), {
      onSuccess: () => {
        toast.success(t("edit_draft_successfully"));
        navigate(-1);
      },
    });

  /**
   * handlers
   */
  const handleDescription = (formProp: any) => (value: string) => {
    if (value === "<p><br></p>" || value === null) {
      setValue(formProp, "");
    } else {
      setValue(formProp, value, { shouldValidate: true });
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setCheckboxValue((prevState) => {
      const newState = {
        ...prevState,
        [name as keyof typeof prevState]: checked, // Ensure name is treated as a valid key
      };

      // Compute selected ids after state is updated
      const selectedIds = Object.keys(newState)
        .filter((key) => newState[key as keyof typeof newState])
        .map(
          (key) => typeOfEmployments(t).find((item) => item.name === key)?.id
        )
        .filter((id): id is number => id !== undefined);

      setSelectedIds(selectedIds);

      return newState;
    });
  };
  const onSubmit = async (data: AddJobOfferDto) => {
    if (await trigger(["title", "jobDescription", "employmentTypes"])) {
      editDraft({
        ...data,
        employmentTypes: selectedIds,
        enterpriseDescription: encodeURIComponent(data.enterpriseDescription),
        functionDescription: encodeURIComponent(data.functionDescription),
        jobDescription: encodeURIComponent(data.jobDescription),
        offerDescription: encodeURIComponent(data.offerDescription),
        teaser: encodeURIComponent(data.teaser),
      });
    }
  };

  /**
   * effects
   */
  useEffect(() => {
    if (jobDetails) {
      if (jobDetails.enterpriseDescription?.startsWith("%")) {
        setValue(
          "enterpriseDescription",
          decodeURIComponent(jobDetails.enterpriseDescription)
        );
      } else {
        setValue("enterpriseDescription", jobDetails.enterpriseDescription);
      }
      if (jobDetails.functionDescription?.startsWith("%")) {
        setValue(
          "functionDescription",
          decodeURIComponent(jobDetails.functionDescription)
        );
      } else {
        setValue("functionDescription", jobDetails.functionDescription);
      }
      if (jobDetails.jobDescription?.startsWith("%")) {
        setValue(
          "jobDescription",
          decodeURIComponent(jobDetails.jobDescription)
        );
      } else {
        setValue("jobDescription", jobDetails.jobDescription);
      }
      if (jobDetails.offerDescription?.startsWith("%")) {
        setValue(
          "offerDescription",
          decodeURIComponent(jobDetails.offerDescription)
        );
      } else {
        setValue("offerDescription", jobDetails.offerDescription);
      }
      if (jobDetails.teaser?.startsWith("%")) {
        setValue("teaser", decodeURIComponent(jobDetails.teaser));
      } else {
        setValue("teaser", jobDetails.teaser);
      }
      const employmentId = jobDetails.employmentTypeIds;

      // Initialize checkboxValue based on employmentId
      const newCheckboxValue = typeOfEmployments(t).reduce(
        (acc: any, { id, name }) => {
          acc[name] = employmentId?.includes(id); // Check if the id is in the employmentId array
          return acc;
        },
        {} as { [key: string]: boolean }
      );

      setCheckboxValue(newCheckboxValue);

      // Set selectedIds based on backend data
      setSelectedIds(employmentId as unknown as number[]);
      setValue("employmentTypes", employmentId as unknown as number[]);
    }
  }, [jobDetails]);

  if (jobDetailsLoading) return <CircularIndeterminate />;

  return (
    <div>
      <MainWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between items-center border-b-2 border-b-[#72C2CC] border-solid mb-2">
            <Typography className="text-base font-bold">
              {t("post_a_job")}
            </Typography>
            <Typography className="text-[#3F6FE8] text-base">
              {t("use_10_credits")}
            </Typography>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid">
            <div className="flex flex-col gap-1  ">
              <Typography className="font-bold text-sm">
                {t("job_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("job_description")}
              </Typography>
            </div>
            <div className="my-2">
              <InputField
                placeholder={t("job_title")}
                type="input"
                fullWidth
                variant="standard"
                size="small"
                {...register("title")}
                defaultValue={jobDetails?.title}
                fieldError={errors.title}
                InputProps={{
                  sx: {
                    backgroundColor: "#F4F7F9",
                    borderRadius: "10px",
                    padding: "0 10px",
                  },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("type_of_employment")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("type_of_employment_description")}
              </Typography>
            </div>
            <div>
              <FormGroup row>
                {typeOfEmployments(t).map(({ id, label, name }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={name}
                        checked={checkboxValue[name as keyof StateType]}
                        onChange={handleChange}
                        name={name}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
              {!!errors.employmentTypes && (
                <Typography
                  sx={{ color: "red" }}
                  variant="caption"
                  display="block"
                >
                  {errors.employmentTypes?.message}
                </Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("enterprise_description")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("enterprise_little_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("enterpriseDescription")}
                onChange={handleDescription("enterpriseDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("job_description_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("job_description_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("jobDescription")}
                onChange={handleDescription("jobDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
            {errors.jobDescription && (
              <Typography
                sx={{ color: "red" }}
                variant="caption"
                display="block"
              >
                {errors.jobDescription.message}
              </Typography>
            )}
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("function_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("function_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("functionDescription")}
                onChange={handleDescription("functionDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("offer_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("offer_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("offerDescription")}
                onChange={handleDescription("offerDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <Typography className="font-bold text-sm">
              {t("teaser_title")}
            </Typography>

            <div>
              <ReactQuill
                theme="snow"
                value={watch("teaser")}
                onChange={handleDescription("teaser")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex justify-end gap-1 my-2">
            <Button className="capitalize" onClick={() => navigate(-1)}>
              {t("discard")}
            </Button>
            <LoadingButton
              variant="contained"
              className="capitalize bg-[#2E98FA]"
              type="submit"
              loading={editDraftLoading}
            >
              {t("edit_draft")}
            </LoadingButton>
          </div>
        </form>
      </MainWrapper>
    </div>
  );
};

export default EditDraft;
