import React, { useContext, useEffect, useState } from "react";
import { MainWrapper } from "../../styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputField } from "../../../InputField/InputField";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PostJobPreview from "./PostJobPreview";
import {
  AddJobOfferDto,
  StateType,
} from "../../../../../interfaces/JobOffers.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddJobSchema } from "../../../../../validators/JobOffers.schema";
import { useNavigate } from "react-router-dom";
import { useGetCompanyDescriptionQuery } from "../../../../../rq/hooks/useQueries";
import { AuthContext } from "../../../../../lib/context/AuthContext";
import { useUpdateCompanyDescriptionMutation } from "../../../../../rq/hooks/useJob.mutation";
import { LoaderIcon } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

const PostJob = () => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [enterpriseDescription, setEnterpriseDescription] = useState("");
  const [openJobPreviewModal, setOpenJobPreviewModal] = useState(false);
  const queryClient = useQueryClient();
  const [checkboxValue, setCheckboxValue] = useState({
    cdd: false,
    internship: false,
    jobStudent: false,
  });

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<AddJobOfferDto>({
    defaultValues: {
      employmentTypes: [],
    },
    resolver: yupResolver(AddJobSchema(t)),
  });

  const companyDescription = useGetCompanyDescriptionQuery();
  const {
    mutate: updateCompanyDescription,
    isLoading: isLoadingCompanyDescription,
  } = useUpdateCompanyDescriptionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  useEffect(() => {
    if (companyDescription.data && companyDescription.data.description) {
      setEnterpriseDescription(
        decodeURIComponent(companyDescription.data.description)
      );
    }
  }, [companyDescription.data]);

  /**
   * constants
   */
  const typeOfEmployments = [
    { id: 1, name: "cdd", label: t("cdd") },
    { id: 2, name: "internship", label: t("internship") },
    { id: 3, name: "jobStudent", label: t("job_student") },
  ];

  /**
   * handlers
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setCheckboxValue((prevState) => {
      const newState = {
        ...prevState,
        [name as keyof typeof prevState]: checked, // Ensure name is treated as a valid key
      };

      // Compute selected ids after state is updated
      const selectedIds = Object.keys(newState)
        .filter((key) => newState[key as keyof typeof newState])
        .map((key) => typeOfEmployments.find((item) => item.name === key)?.id)
        .filter((id): id is number => id !== undefined);

      setValue("employmentTypes", selectedIds);

      return newState;
    });
  };

  const handleDescription = (formProp: any) => (value: string) => {
    if (value === "<p><br></p>" || value === null) {
      setValue(formProp, "");
    } else {
      setValue(formProp, value, { shouldValidate: true });
    }
  };

  const onSubmit = async (data: AddJobOfferDto) => {
    if (await trigger(["title", "jobDescription", "employmentTypes"])) {
      setOpenJobPreviewModal(true);
    }
  };

  return (
    <>
      <MainWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between items-center border-b-2 border-b-[#72C2CC] border-solid mb-2">
            <Typography className="text-base font-bold">
              {t("post_a_job")}
            </Typography>
            <Typography className="text-[#3F6FE8] text-base">
              {t("use_10_credits")}
            </Typography>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid">
            <div className="flex flex-col gap-1  ">
              <Typography className="font-bold text-sm">
                {t("job_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("job_description")}
              </Typography>
            </div>
            <div className="my-2">
              <InputField
                placeholder={t("job_title")}
                type="input"
                fullWidth
                variant="standard"
                size="small"
                {...register("title")}
                fieldError={errors.title}
                InputProps={{
                  sx: {
                    backgroundColor: "#F4F7F9",
                    borderRadius: "10px",
                    padding: "0 10px",
                  },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("type_of_employment")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("type_of_employment_description")}
              </Typography>
            </div>
            <div>
              <FormGroup row>
                {typeOfEmployments.map(({ id, label, name }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={name}
                        checked={checkboxValue[name as keyof StateType]}
                        onChange={handleChange}
                        name={name}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
              {!!errors.employmentTypes && (
                <Typography
                  sx={{ color: "red" }}
                  variant="caption"
                  display="block"
                >
                  {errors.employmentTypes?.message}
                </Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("enterprise_description")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("enterprise_little_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={enterpriseDescription}
                onChange={setEnterpriseDescription}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
            <Button
              type="button"
              disabled={enterpriseDescription === user?.description}
              onClick={() => {
                updateCompanyDescription({
                  description: enterpriseDescription,
                });
              }}
              variant="outlined"
              className="w-fit flex gap-2 disabled:cursor-not-allowed"
            >
              <Typography
                className={` text-sm capitalize ${
                  enterpriseDescription === user?.description
                    ? "text-[#A7A7A7]"
                    : "text-[#3F6FE8]"
                }`}
              >
                {t("save_to_profile")}
              </Typography>{" "}
              {isLoadingCompanyDescription && (
                <LoaderIcon className="animate-spin" />
              )}
            </Button>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("job_description_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("job_description_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("jobDescription")}
                onChange={handleDescription("jobDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
            {errors.jobDescription && (
              <Typography
                sx={{ color: "red" }}
                variant="caption"
                display="block"
              >
                {errors.jobDescription.message}
              </Typography>
            )}
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("function_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("function_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("functionDescription")}
                onChange={handleDescription("functionDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <div className="flex flex-col gap-1">
              <Typography className="font-bold text-sm">
                {t("offer_title")}
              </Typography>
              <Typography className="text-sm text-[#7C8493]">
                {t("offer_description")}
              </Typography>
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={watch("offerDescription")}
                onChange={handleDescription("offerDescription")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 border-b-2 border-b-[#72C2CC] border-solid pb-10 pt-2 ">
            <Typography className="font-bold text-sm">
              {t("teaser_title")}
            </Typography>

            <div>
              <ReactQuill
                theme="snow"
                value={watch("teaser")}
                onChange={handleDescription("teaser")}
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          </div>
          <div className="flex justify-end gap-1 my-2">
            <Button className="capitalize" onClick={() => navigate(-1)}>
              {t("discard")}
            </Button>
            <Button
              variant="contained"
              className="capitalize bg-[#2E98FA]"
              type="submit"
            >
              {t("preview")}
            </Button>
          </div>
        </form>
      </MainWrapper>
      <PostJobPreview
        open={openJobPreviewModal}
        handleClose={() => setOpenJobPreviewModal(false)}
        jobDetails={{
          ...getValues(),
          jobDescription: encodeURIComponent(getValues("jobDescription")),
          enterpriseDescription: encodeURIComponent(enterpriseDescription),
          functionDescription: encodeURIComponent(
            getValues("functionDescription")
          ),
          teaser: encodeURIComponent(getValues("teaser")),
          offerDescription: encodeURIComponent(getValues("offerDescription")),
        }}
      />
    </>
  );
};

export default PostJob;
