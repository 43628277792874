import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MainWrapper, Title } from "../../../styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import CircularIndeterminate from "../../../../../../components/CircularIndeterminate/CircularIndeterminate";
import { Button, Pagination, Typography } from "@mui/material";
import { useGetCreditsSpentHistory } from "../../../../../../rq/hooks/useQueries";
import { Link } from "react-router-dom";

export default function CreditHistory() {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const [currentCreditSpentHistoryTab, setCurrentCreditSpentHistoryTab] =
    React.useState("Cv");
  const { data, isLoading } = useGetCreditsSpentHistory(
    page,
    currentCreditSpentHistoryTab
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <MainWrapper>
      <Title>{t("credit_history.title")}</Title>
      <div>
        <Button
          type="button"
          variant="text"
          onClick={() => {
            setCurrentCreditSpentHistoryTab("Cv");
          }}
        >
          <Typography
            className={`text-sm lowercase capitalize ${
              currentCreditSpentHistoryTab === "Cv"
                ? "text-primary"
                : "text-zinc-600"
            }`}
          >
            {t("credit_history.cv")}
          </Typography>
        </Button>
        <Button
          type="button"
          variant="text"
          onClick={() => {
            setCurrentCreditSpentHistoryTab("Job");
          }}
        >
          <Typography
            className={`text-sm lowercase capitalize ${
              currentCreditSpentHistoryTab === "Job"
                ? "text-primary"
                : "text-zinc-600"
            }`}
          >
            {t("credit_history.job_posts")}
          </Typography>
        </Button>
      </div>
      {currentCreditSpentHistoryTab === "Cv" ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("billing_history.date")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("credit_history.candidate")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("credit_history.points_spent")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("credit_history.download")}
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <CircularIndeterminate />
            ) : (
              <TableBody>
                {data?.data.cvCreditsSpent?.data?.map((row: any) => (
                  <TableRow key={row.name} sx={{ td: { border: 0 } }}>
                    <TableCell>
                      {dayjs(row.date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>{row.candidate}</TableCell>
                    <TableCell>{row.pointsSpent}</TableCell>
                    <TableCell>
                      <a
                        href={row.cvPath}
                        download
                        style={{
                          textDecoration: "underline",
                          color: "#487BD9",
                        }}
                      >
                        CV
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <Pagination
            count={data?.metaData?.pageCount}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            hideNextButton
            hidePrevButton
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
              mt: 2,
            }}
          />
        </TableContainer>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("billing_history.date")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("credit_history.details")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("credit_history.amount")}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                >
                  {t("credit_history.post")}
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <CircularIndeterminate />
            ) : (
              <TableBody>
                {data?.data.jobCreditsSpent?.data?.map((row: any) => (
                  <TableRow key={row.name} sx={{ td: { border: 0 } }}>
                    <TableCell>
                      {dayjs(row.date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>{row.candidate}</TableCell>
                    <TableCell>{row.pointsSpent}</TableCell>
                    <TableCell>
                      <Link
                        to={`/job-offer/${row.jobId}`}
                        style={{
                          textDecoration: "underline",
                          color: "#487BD9",
                        }}
                      >
                        {t("credit_history.go_to_the_post")}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <Pagination
            count={data?.metaData?.pageCount}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            hideNextButton
            hidePrevButton
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
              mt: 2,
            }}
          />
        </TableContainer>
      )}
    </MainWrapper>
  );
}
