import React, { useEffect, useState } from "react";
import { Container, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/Card/Card";
import { useGetAllBusinessCardsQuery } from "../../../../rq/hooks/useQueries";
import { CardsWrapper, Description, MainWrapper, Title } from "../styles";

export default function CompaniesCards({ searchWord, search }: any) {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const { data, refetch, isLoading } = useGetAllBusinessCardsQuery(
    page,
    null,
    null,
    {
      keepPreviousData: true,
    }
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    refetch();
  }, [search]);

  return (
    <Container>
      <MainWrapper>
        <Title>{t("home.companies_cards.title")}</Title>
        <Description>{t("home.companies_cards.description")}</Description>
        <CardsWrapper>
          {data?.data?.map((card: any, i: any) => (
            <Card
              key={i}
              profilePicture={card.profilePicture}
              candidateUserId={card.candidateUserId}
              candidateId={card.candidateId}
              professions={card.profession}
              businessUserId={card.businessUserId}
              businessId={card.businessId}
              name={card.name}
              lastName={card.lastName}
              country={card.country}
              video={card.video}
              videoViews={card.videoViews}
              likes={card.likes}
              saves={card.saves}
              isMyFavorite={card.isMyFavorite}
              queryKeyString="get-all-business-cards"
              likedFromMe={card.likedFromMe}
              pageNumber={data.metaData.pageNumber}
              refetch={refetch}
              videoId={card.videoId}
              boughtFromCurrentUser={card.boughtFromCurrentUser}
              style={{ border: "1px solid #D0D0D0", borderRadius: "20px" }}
            />
          ))}
        </CardsWrapper>
        <Pagination
          count={data?.metaData?.pageCount}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          hidePrevButton
          hideNextButton
          sx={{ alignSelf: "center" }}
        />
      </MainWrapper>
    </Container>
  );
}
