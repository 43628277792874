import { useNavigate } from "react-router-dom";
import { RegisterButton } from "./styles";
import { useTranslation } from "react-i18next";
import VideoWrapper from "../VideoWrapper/VideoWrapper";
import home_second from "../../../../assets/icons/home_what_it_is_second.png";

export default function RegisterNow() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col gap-4 py-4 border-2 w-full relative">
      <VideoWrapper
        src="../../../../assets/videos/video_subscribe.mp4"
        light={home_second}
      />
      <div className="w-full z-10 h-full flex justify-center items-center">
        <div className="w-[200px] flex justify-center gap-8 items-center flex-col">
          <RegisterButton onClick={() => navigate("/register")}>
            {t("home.register.register_now")}
          </RegisterButton>
        </div>
      </div>
    </div>
  );
}
