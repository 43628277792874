import {
  SideBarModel,
  UserModel,
  SideBarBusinessModel,
  PackagePlan,
} from '../../interfaces/types/User';
import axios from '../../utils/axios';
import { NotificationModel } from '../../interfaces/Notification.model';

interface BusinessTypeModel {
  id: number;
  insertedDate: string;
  name: string;
}

export const getUniversities = async (): Promise<any[]> => {
  const { data } = await axios.get('education');
  return data.data;
};

export const getStudyFieldsOfStudy = async (): Promise<any[]> => {
  const { data } = await axios.get('fieldsOfStudy');
  return data.data;
};

export const getProfessions = async (): Promise<any> => {
  const { data } = await axios.get(`profession`);
  return data.data;
};

export const getLanguages = async (): Promise<any[]> => {
  const { data } = await axios.get('language/getAllLanguages');
  return data.data;
};

export const getLanguagesSkills = async (): Promise<any[]> => {
  const { data } = await axios.get('language/getAllLanguageSkillLevels');
  return data.data;
};

export const getBusinessTypes = async (): Promise<BusinessTypeModel[]> => {
  const { data } = await axios.get('businessType');
  return data.data;
};

// Profile Queries

export const getProfile = async (): Promise<UserModel> => {
  const { data } = await axios.get('candidate/myProfile');
  return data.data;
};

export const getSideBarProfile = async (): Promise<SideBarModel> => {
  const { data } = await axios.get('candidate/sideBar');
  return data.data;
};

export const getBusinessProfile = async (): Promise<UserModel> => {
  const { data } = await axios.get('business/profile/my');
  return data.data;
};

// Get All Queries
export const getAllCountries = async () => {
  const { data } = await axios.get('country/getAllCountries');
  return data.data;
};

export const getCompanyDescription = async () => {
  const { data } = await axios.get('Business/Description');
  return data.data;
};

export const getAllSkills = async (): Promise<any> => {
  const { data } = await axios.get('skills');
  return data.data;
};

export const getCvPreview = async (fileId: any) => {
  const { data } = await axios.get(`candidate/getCv/${fileId}`);
  return data.data;
};

export const getAllAccomplishments = async () => {
  const { data } = await axios.get('accomplishment');
  return data.data;
};

export const getMyLikedVideos = async (pageParam: number, queryKey: any) => {
  const { data } = await axios.get(
    `candidate/myLikedVideos?pageNumber=${pageParam}&pageSize=5&searchWord=${queryKey}`
  );
  return data;
};

export const getInterestedOnYou = async (pageNumber: number) => {
  const { data } = await axios.get(
    `candidate/interestedOnMe?pageNumber=${pageNumber}&pageSize=5`
  );
  return data;
};

// Feed All Cards
export const getAllCards = async (pageParam: number, queryKey: any) => {
  const { data } = await axios.get(
    `feed/getAllCards?pageNumber=${pageParam}&pageSize=5&searchWord=${queryKey}`
  );
  return data;
};

// BUSINESS PROFILE SIDEBAR

export const getSideBarBusinessProfile =
  async (): Promise<SideBarBusinessModel> => {
    const { data } = await axios.get('business/sideBar');
    return data.data;
  };

// GET MY FAVOURITE USERS

export const getMyFavouriteUsers = async (pageParam: number, queryKey: any) => {
  const { data } = await axios.get(
    `candidate/getMyFavoriteUsers?pageNumber=${pageParam}&pageSize=5&searchWord=${queryKey}`
  );
  return data;
};

// GET ALL PACKAGES PLANS

export const getPackagesPlans = async (): Promise<PackagePlan[]> => {
  const { data } = await axios.get('package/getAllPackages');
  return data.data;
};

// GET PROFILE, WHEN BUSINESS WANTS TO SEE CANDIDATE'S PROFILE

export const getCandidateProfileFromBusiness = async (
  candidateId: any
): Promise<UserModel> => {
  const { data } = await axios.get(
    `candidate/getCandidateProfile/${candidateId}`
  );
  return data.data;
};

export const getStripeIntent = async (packageId: any): Promise<any> => {
  const { data } = await axios.get(`package/stripeCheckout/${packageId}`);
  return data.data;
};

// Homepage candidates' cards
export const getCandidateCards = async (
  pageNumber: any,
  searchWord: any,
  candidateId?: any,
  professionId?: number[],
  fieldOfStudyId?: number,
  educationId?: number
) => {
  const { data } = await axios.post(
    `candidate/getAllCandidateCards?pageNumber=${pageNumber}&pageSize=9`,
    {
      ...(candidateId && { candidateId: candidateId }),
      searchWord,
      ...(professionId &&
        professionId?.length > 0 && { professionId: professionId }),
      fieldOfStudyId,
      educationId,
    }
  );
  return data;
};

export const getBusinessCards = async (
  pageNumber: any,
  searchWord: any,
  businessId: any
) => {
  const { data } = await axios.get(
    `business/getAllBusinessCards?pageNumber=${pageNumber}&pageSize=6`,
    {
      params: {
        businessUserId: businessId,
        searchWord: searchWord,
      },
    }
  );
  return data;
};

// History of Payments
export const getHistoryOfPayments = async (pageNumber: any) => {
  const { data } = await axios.get(
    `business/historyOfPayments?pageNumber=${pageNumber}&pageSize=4`
  );
  return data;
};

export const getCreditsSpentHistory = async (pageNumber: any, creditSpentType: string) => {
  const { data } = await axios.get(
    `Business/CreditSpentHistory`, {
    params: {
      pageSize: 4,
      pageNumber: pageNumber,
      creditSpentType: creditSpentType
    }
  });

  console.log(data, 'data');
  return data;
};

// Notifications

export const fetchNotifications = async (): Promise<NotificationModel[]> => {
  const { data } = await axios.get('Notification/myNotifications');

  return data.data;
};

export const fetchMySubscription = async (): Promise<any> => {
  const { data } = await axios.get('subscription/my');
  return data.data;
};

export const downloadCandidateFiles = async (id: number) => {
  const { data } = await axios.get(`Candidate/DownloadCandidateFiles/${id}`, {
    responseType: 'blob',
  });
  return data;
};
