import {
  AddJobOfferDto,
  BusinessJobAdsModel,
  JobAdCandidateModel,
  JobAdDetailsModel,
  JobAdsModel,
} from '../../interfaces/JobOffers.model';
import axios from '../../utils/axios';

export const addJobOffer = async (payload: AddJobOfferDto) => {
  await axios.post('businessJobs', payload);
};

export const updateCompanyDescription = async (payload: {
  description: string;
}) => {
  await axios.put('Business/Description', payload);
}

export const deleteJobOffer = async (id: number) => {
  await axios.delete(`businessJobs/${id}`);
};

export const removeAdFromDraft = async (id: number) => {
  return await axios.put(`businessJobs/${id}/remove-from-draft`);
};

export const getJobAds = async (params: {
  employmentTypes: number[];
}): Promise<JobAdsModel[]> => {
  const employmentType = params.employmentTypes
    .map((id) => `employmentTypes=${id}`)
    .join('&');
  const { data } = await axios.get(`jobs?${employmentType}`);
  return data.data;
};

export const getBusinessJobs = async (): Promise<BusinessJobAdsModel[]> => {
  const { data } = await axios.get('businessJobs');
  return data.data;
};

export const getAppliedJobs = async (
  pageParam: number,
  queryKey: any
): Promise<JobAdsModel[]> => {
  const { data } = await axios.get(
    `candidateJob/applied-jobs?pageNumber=${pageParam}&pageSize=5&searchWord=${queryKey}`
  );
  return data;
};

export const getJobAdDetails = async (
  jobId: number
): Promise<JobAdDetailsModel> => {
  const { data } = await axios.get(`jobs/${jobId}`);
  return data.data;
};

export const applyForJob = async (
  jobId: number,
  payload: { message: string }
) => {
  await axios.post(`candidateJob/apply-for-job/${jobId}`, payload);
};

export const getJobAdCandidates = async (
  jobId: number
): Promise<JobAdCandidateModel> => {
  const { data } = await axios.get(`BusinessJobs/${jobId}/applicants`);
  return data.data;
};

export const cancelSubscription = async (userSubId: number) => {
  await axios.put(`subscription/${userSubId}/cancel`);
};

export const reactivateSubscription = async (userSubId: number) => {
  await axios.put(`subscription/${userSubId}/reactivate`);
};

export const editJobDraft = async (id: number, payload: AddJobOfferDto) => {
  await axios.put(`businessJobs/${id}`, payload);
};
