import { Container } from "@mui/material";
import ReactPlayer from "react-player";
import { VideoWrapperStyle } from "./styles";
import myfirstjob from "../../../../assets/images/myfirstjob.png";

export default function VideoWrapper({
  src,
  light,
}: {
  src?: string;
  light?: string;
}) {
  return (
    <Container sx={{ mt: 8 }}>
      <VideoWrapperStyle>
        <ReactPlayer
          light={light ?? myfirstjob}
          url={
            src ??
            "https://myfirstjob.blob.core.windows.net/webapp/MFJ_FINAL.mp4"
          }
          controls
        />
      </VideoWrapperStyle>
    </Container>
  );
}
