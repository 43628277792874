import React, { FC } from "react";
import { BootstrapDialog } from "../../../../../components/BootstrapDialog/BootstrapDialog";
import { Button, DialogContent, DialogProps, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useTranslation } from "react-i18next";
import { useAddJobOfferMutation } from "../../../../../rq/hooks/useJob.mutation";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface PostJobWarningProps extends DialogProps {
  handleClose: () => void;
  jobDetails: any;
}

const PostJobWarning: FC<PostJobWarningProps> = ({
  open,
  handleClose,
  jobDetails,
}) => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: addJobOffer, isLoading: addJobOfferLoading } =
    useAddJobOfferMutation();
  const queryClient = useQueryClient();

  /**
   * hanlders
   */
  const handleCloseModal = () => handleClose();
  const handleAddJobOffer = () => {
    addJobOffer(
      {
        ...jobDetails,
        enterpriseDescription:
          jobDetails.enterpriseDescription === "undefined"
            ? ""
            : jobDetails.enterpriseDescription,
        functionDescription:
          jobDetails.functionDescription === "undefined"
            ? ""
            : jobDetails.functionDescription,
        jobDescription:
          jobDetails.jobDescription === "undefined"
            ? ""
            : jobDetails.jobDescription,
        offerDescription:
          jobDetails.offerDescription === "undefined"
            ? ""
            : jobDetails.offerDescription,
        teaser: jobDetails.teaser === "undefined" ? "" : jobDetails.teaser,
        isDraft: false,
      },
      {
        onSuccess: () => {
          toast.success(t("job_published_successfully"));
          handleCloseModal();
          queryClient.invalidateQueries();
          navigate("/Business/profile/my-job-offers");
        },
      }
    );
  };
  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            width: "100%",
          },
        }}
      >
        <DialogContent className="flex flex-col justify-center items-center">
          <ErrorOutlineOutlinedIcon className="fill-[#FDC01C] text-7xl" />
          <Typography className="text-base">
            {t("publish_job_warning")}
          </Typography>
          <div className="flex gap-1 mt-2">
            <Button
              variant="contained"
              className="capitalize text-black"
              onClick={handleCloseModal}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              className="bg-[#72C2CC] hover:bg-[#72C2CC] capitalize text-sm"
              loading={addJobOfferLoading}
              onClick={handleAddJobOffer}
            >
              {t("confirm_publication")}
            </LoadingButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default PostJobWarning;
