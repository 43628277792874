import { InputAdornment, TextField } from "@mui/material";
import {
  CoverContainer,
  CoverContent,
  CoverImage,
  SearchWrapper,
  SearchButton,
} from "./styles";
import coverImg from "../../../../assets/icons/homepage_logo.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../lib/context/AuthContext";
import { useTranslation } from "react-i18next";

interface SearchProps {
  searchWord: string;
  search: any;
  setSearch: (search: any) => void;
  setSearchWord: (searchWord: string) => void;
}

export default function CoverSection({
  setSearchWord,
  setSearch,
  searchWord,
}: SearchProps) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const handleChange = (e: any) => {
    setSearchWord(e.target.value);
  };

  const handleSearch = () => {
    setSearch((prev: any) => !prev);
    if (user?.candidateId || !user) {
      navigate(`/business/search?searchWord=${searchWord}`);
    } else {
      navigate(`/candidate/search?searchWord=${searchWord}`);
    }
  };

  const { t } = useTranslation();
  return (
    <CoverContainer>
      <CoverContent>
        <CoverImage src={coverImg} loading="lazy" />
        <SearchWrapper>
          <TextField
            size="small"
            fullWidth
            placeholder={
              user?.candidateId || !user
                ? t("search_for_company")
                : t("search_for_student")
            }
            id="input-with-icon-textfield"
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              input: {
                padding: 0,
              },
            }}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={handleSearch}>
                    {t("card.search.title")}
                  </SearchButton>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            variant="standard"
          />
        </SearchWrapper>
      </CoverContent>
    </CoverContainer>
  );
}
