import React, { useContext, useState, useCallback } from "react";
import { MainWrapper } from "../../modals/components/layouts/student-dashboard/EditStudentProfile/styles";
import {
  CardHeaderWrapper,
  UserBasicInfoWrapper,
  LikedAvatar,
  NameLocationWrapper,
  Location,
  CardVideoWrapper,
  IconsWrapper,
  IconWrapper,
  Label,
  ButtonsWrapper,
  LikeButton,
  AvatarWrapper,
  NameLink,
  NameNoLink,
  NameLinkBusiness,
  PlayerIconWrapper,
} from "./styles";
import {
  BusinessRounded,
  Person,
  StarRounded,
  Favorite,
  ThumbUpRounded,
  Visibility,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import MoreMenu from "./MoreMenu/MoreMenu";
import { AuthContext } from "../../lib/context/AuthContext";
import { Button, Tooltip, Typography } from "@mui/material";
import {
  useAddBusinessVideoView,
  useAddCandidateVideoView,
  useAddUserToFavorites,
  useAddVideoLike,
  useDeleteLikedVideo,
  useDeleteUserToFavorites,
} from "../../rq/hooks/useEditProfile.mutation";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import StudentViewBusiness from "../../modals/components/layouts/student-dashboard/StudentViewBusiness/StudentViewBusiness";
import UnsaveProfileModal from "./MoreMenu/UnsaveProfileModal";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { PlayerContext } from "../../lib/context/PlayerContext";
import toast from "react-hot-toast";
import { FlexBox } from "../Flexbox";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import digitalBookBackground from "../../assets/images/digital_book_background.png";
import { downloadCandidateFiles } from "../../rq/queries";
import UnlockFieldModal from "../../modals/components/layouts/business-dashboard/BusinessViewStudent/UnlockFieldModal/UnlockFieldModal";
import { addCandidateVideoView } from "../../rq/mutations/EditProfile.mutation";
import { useQueryClient, QueryClient } from "@tanstack/react-query";

type OptimisticUpdateParams<T> = {
  queryClient: QueryClient;
  queryKey: any[];
  updateFn: (previousData: T | undefined) => T;
};

const Card = React.memo(
  ({
    profilePicture,
    candidateUserId,
    candidateId,
    businessUserId,
    businessId,
    name,
    lastName,
    country,
    video,
    likes,
    videoViews,
    saves,
    pageNumber,
    isMyFavorite: initialIsMyFavorite,
    likedFromMe: initialLikedFromMe,
    refetch,
    videoId,
    boughtFromCurrentUser,
    email,
    phoneNumber,
    professions,
    digitalBook,
    downloadCV,
    downloadDigitalBook,
    hasCv,
    cvPath,
    ...props
  }: any) => {
    //hooks
    const { t } = useTranslation();
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [playedVideo, setPlayedVideo] = useState<boolean>(true);
    const [openUnsaveProfile, setOpenUnsaveProfile] = useState<boolean>(false);
    const [openBusinessModal, setOpenBusinessModal] = useState<boolean>(false);
    const [showControls, setShowControls] = useState<boolean>(false);
    const [openUnlockModal, setOpenUnlockModal] = useState<boolean>(false);
    const [isMyFavorite, setIsMyFavorite] =
      useState<boolean>(initialIsMyFavorite);
    const [downloadingCV, setDownloadingCv] = useState<boolean>(false);
    const [likedFromMe, setLikedFromMe] = useState<boolean>(initialLikedFromMe);
    const queryClient = useQueryClient();
    const location = useLocation();
    function setQueryDataOptimistically<T>({
      queryClient,
      queryKey,
      updateFn,
    }: OptimisticUpdateParams<T>) {
      // Snapshot the previous value
      const previousData = queryClient.getQueryData<T>(queryKey);
      // Optimistically update the query data
      if (previousData) {
        queryClient.setQueryData(queryKey, updateFn(previousData));
      }
      console.log(previousData, "previousData");
      return { previousData };
    }

    // react mutations
    const { mutate: addUserToFavorites } = useAddUserToFavorites({
      onMutate: () => {
        console.log("addUserToFavorites onMutate");
        const queryKey = ["get-all-candidate-cards", pageNumber];
        // Use the generic helper function
        return setQueryDataOptimistically({
          queryClient,
          queryKey,
          updateFn: (previousData: any) => ({
            ...previousData,
            data: previousData?.data?.map((item: any) =>
              item.candidateId === candidateId
                ? { ...item, isMyFavorite: true, saves: item.saves + 1 }
                : item
            ),
          }),
        });
      },
    });

    const { mutate: deleteUserToFavorites } = useDeleteUserToFavorites({
      onMutate: () => {
        const queryKey = ["get-all-candidate-cards", pageNumber];

        // Use the generic helper function
        return setQueryDataOptimistically({
          queryClient,
          queryKey,
          updateFn: (previousData: any) => ({
            ...previousData,
            data: previousData?.data?.map((item: any) =>
              item.candidateId === candidateId
                ? { ...item, isMyFavorite: false, saves: item.saves - 1 }
                : item
            ),
          }),
        });
      },
    });

    const { mutate: addVideoView } = useAddCandidateVideoView({
      onMutate: () => {
        const queryKey = ["get-all-candidate-cards", pageNumber];

        // Use the generic helper function
        return setQueryDataOptimistically({
          queryClient,
          queryKey,
          updateFn: (previousData: any) => ({
            ...previousData,
            data: previousData?.data?.map((item: any) =>
              item.candidateId === candidateId
                ? { ...item, videoViews: item.videoViews + 1 }
                : item
            ),
          }),
        });
      },
    });

    const { mutate: addBusinessVideoView } = useAddBusinessVideoView({
      onMutate: () => {
        const queryKey = ["get-all-business-cards", pageNumber];

        return setQueryDataOptimistically({
          queryClient,
          queryKey,
          updateFn: (previousData: any) => ({
            ...previousData,
            data: previousData?.data?.map((item: any) =>
              item.businessId === businessId
                ? { ...item, videoViews: item.videoViews + 1 }
                : item
            ),
          }),
        });
      },
    });

    const { mutate: addLike, isLoading: addVideoLikeLoading } = useAddVideoLike(
      {
        onMutate: () => {
          const queryKey = [
            location.pathname.includes("/profile/feed")
              ? "get-all-cards"
              : candidateId
              ? "get-all-candidate-cards"
              : "get-all-business-cards",
            pageNumber,
          ];

          // Use the generic helper function
          return setQueryDataOptimistically({
            queryClient,
            queryKey,
            updateFn: (previousData: any) => ({
              ...previousData,
              data: candidateId
                ? previousData?.data?.map((item: any) =>
                    item.candidateId === candidateId
                      ? { ...item, likes: item.likes + 1 }
                      : item
                  )
                : previousData?.data?.map((item: any) =>
                    item.businessId === businessId
                      ? { ...item, likes: item.likes + 1 }
                      : item
                  ),
            }),
          });
        },
      }
    );

    console.log(
      location.pathname,
      "location.pathname: ",
      location.pathname.includes("/profile/feed")
    );
    const { mutate: deleteLike, isLoading: deleteVideoLikeLoading } =
      useDeleteLikedVideo({
        onMutate: () => {
          const queryKey = [
            location.pathname.includes("/profile/feed")
              ? "get-all-cards"
              : candidateId
              ? "get-all-candidate-cards"
              : "get-all-business-cards",
            pageNumber,
          ];

          // Use the generic helper function
          return setQueryDataOptimistically({
            queryClient,
            queryKey,
            updateFn: (previousData: any) => ({
              ...previousData,
              data: candidateId
                ? previousData?.data?.map((item: any) =>
                    item.candidateId === candidateId
                      ? { ...item, likes: item.likes - 1 }
                      : item
                  )
                : previousData?.data?.map((item: any) =>
                    item.businessId === businessId
                      ? { ...item, likes: item.likes - 1 }
                      : item
                  ),
            }),
          });
        },
      });

    // handlers
    const handleAddUserToFavorites = useCallback(() => {
      if (isMyFavorite) {
        setIsMyFavorite(false);
        console.log("entered this phase favoriting", candidateUserId);
        deleteUserToFavorites({ favoriteUserId: candidateUserId });
      } else {
        setIsMyFavorite(true);
        console.log("entered this phase defavoriting", candidateUserId);
        addUserToFavorites({ favoriteUserId: candidateUserId });
      }
    }, [
      isMyFavorite,
      addUserToFavorites,
      deleteUserToFavorites,
      candidateUserId,
    ]);

    const handleLikeUserCard = useCallback(() => {
      if (likedFromMe) {
        setLikedFromMe(false);
        deleteLike({
          likedUserId: candidateUserId ? candidateUserId : businessUserId,
        });
      } else {
        setLikedFromMe(true);
        addLike({
          likedUserId: candidateUserId ? candidateUserId : businessUserId,
        });
      }
    }, [likedFromMe, addLike, deleteLike, candidateUserId, businessUserId]);

    const handleAddVideoView = useCallback(() => {
      if (playedVideo) {
        if (candidateId) {
          addVideoView({ candidateVideoId: videoId });
        } else {
          addBusinessVideoView({ businessVideoId: videoId });
        }
        setPlayedVideo(false);
      }
    }, [
      playedVideo,
      candidateId,
      addCandidateVideoView,
      addBusinessVideoView,
      videoId,
    ]);

    const handleClickProfilePicture = useCallback(() => {
      if (candidateId && user?.businessId) {
        navigate(`/candidate/profile/${candidateId}`);
      } else if (businessId && user?.candidateId) {
        setOpenBusinessModal(true);
      }
    }, [candidateId, user, businessId, navigate]);

    const handlePrint = useCallback(async () => {
      try {
        const blob = await downloadCandidateFiles(Number(candidateId));
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      } catch (error) {
        console.error("Failed to fetch the print label:", error);
      }
    }, [candidateId]);

    // constants
    const shouldDisplay =
      !user || (!!user?.businessId && !businessUserId) || !!user?.candidateId;

    const showCursor =
      (candidateId && user?.businessId) || (businessId && user?.candidateId);
    const { isPlaying, play } = useContext(PlayerContext);

    return (
      <>
        {shouldDisplay && (
          <MainWrapper
            {...props}
            className="hover:scale-[102%] transition-all duration-300"
          >
            <CardHeaderWrapper>
              <FlexBox justifyContent="space-between">
                <UserBasicInfoWrapper>
                  {profilePicture ? (
                    <LikedAvatar
                      isCandidate={candidateUserId}
                      src={profilePicture}
                      onClick={handleClickProfilePicture}
                      style={{ cursor: showCursor && "pointer" }}
                    />
                  ) : (
                    <AvatarWrapper
                      isCandidate={candidateUserId}
                      onClick={handleClickProfilePicture}
                      style={{ cursor: showCursor && "pointer" }}
                    >
                      {candidateUserId ? (
                        <Person sx={{ color: "white" }} />
                      ) : (
                        <BusinessRounded sx={{ color: "white" }} />
                      )}
                    </AvatarWrapper>
                  )}
                  <NameLocationWrapper>
                    {user?.businessId && candidateId ? (
                      <NameLink to={`/candidate/profile/${candidateId}`}>
                        {name} {lastName}
                      </NameLink>
                    ) : user?.candidateId && businessId ? (
                      <>
                        <NameLinkBusiness
                          onClick={() => setOpenBusinessModal(true)}
                        >
                          {name} {lastName}
                        </NameLinkBusiness>
                        <StudentViewBusiness
                          open={openBusinessModal}
                          setOpen={setOpenBusinessModal}
                          id={businessUserId}
                        />
                      </>
                    ) : (
                      <NameNoLink>
                        {name} {lastName}
                      </NameNoLink>
                    )}
                    <Location>{country}</Location>
                  </NameLocationWrapper>
                </UserBasicInfoWrapper>

                {user?.businessId && candidateUserId && isAuthenticated && (
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      isMyFavorite
                        ? t("card.remove_from_stars")
                        : t("card.add_to_stars")
                    }
                  >
                    <div className="flex items-center flex-row-reverse gap-1">
                      <StarRounded
                        onClick={handleAddUserToFavorites}
                        fontSize="small"
                        sx={{
                          color: isMyFavorite ? "black" : "#C5C5C5",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </FlexBox>
              <Typography
                mt={1}
                fontSize="14px"
                fontWeight={500}
                sx={{ color: "#487BD9" }}
              >
                {professions?.map((profession: any) => profession).join(", ")}
              </Typography>
            </CardHeaderWrapper>

            <CardVideoWrapper>
              {video ? (
                <>
                  {!showControls && (
                    <PlayerIconWrapper
                      onClick={() => {
                        setShowControls(true);
                        play(videoId);
                      }}
                    >
                      <PlayArrowIcon fontSize="large" sx={{ color: "white" }} />
                    </PlayerIconWrapper>
                  )}
                  <ReactPlayer
                    url={video}
                    controls={showControls}
                    width="100%"
                    height="200px"
                    onPause={() => {
                      setShowControls(false);
                    }}
                    playing={showControls && isPlaying(videoId)}
                    onPlay={handleAddVideoView}
                    onEnded={() => {
                      setShowControls(false);
                    }}
                  />
                </>
              ) : digitalBook ? (
                <a
                  href={digitalBook}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    border: "1px solid #F4F4F4",
                    borderRadius: "10px",
                    height: "200px",
                    cursor: "pointer",
                    background: `url(${digitalBookBackground})`,
                    backgroundSize: "100% 200px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <VisibilityRoundedIcon sx={{ fill: "#72C2CC" }} />
                  <p
                    style={{
                      color: "#72C2CC",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {t("digital_book")}
                  </p>
                </a>
              ) : hasCv && !cvPath ? (
                <button
                  type="button"
                  onClick={() => {
                    if (isAuthenticated) {
                      setDownloadingCv(true);
                      setOpenUnlockModal(true);
                    } else if (!isAuthenticated) {
                      navigate("/login");
                      toast.error(t("unauthorized_to_cv"));
                    }
                  }}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    border: "1px solid #F4F4F4",
                    borderRadius: "10px",
                    height: "200px",
                    cursor: "pointer",
                    background: `url(${digitalBookBackground})`,
                    backgroundSize: "100% 200px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  className="w-full"
                >
                  <VisibilityRoundedIcon sx={{ fill: "#72C2CC" }} />
                  <p
                    style={{
                      color: "#72C2CC",
                      fontFamily: "Montserrat",
                    }}
                  >
                    CV
                  </p>
                </button>
              ) : (
                <a
                  href={cvPath}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    border: "1px solid #F4F4F4",
                    borderRadius: "10px",
                    height: "200px",
                    cursor: "pointer",
                    background: `url(${digitalBookBackground})`,
                    backgroundSize: "100% 200px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  className="bg-gradien-to-r from-zinc-300 to-white"
                >
                  <VisibilityRoundedIcon sx={{ fill: "#72C2CC" }} />
                  <p
                    style={{
                      color: "#72C2CC",
                      fontFamily: "Montserrat",
                    }}
                  >
                    CV
                  </p>
                </a>
              )}

              <IconsWrapper>
                <IconWrapper>
                  <Favorite
                    sx={{
                      fontSize: "12px",
                      color: "#9D9D9D",
                      mr: 0.5,
                    }}
                  />
                  <Label>
                    {likes} {t("card.likes")}
                  </Label>
                </IconWrapper>
                <IconWrapper>
                  <Visibility
                    sx={{
                      fontSize: "12px",
                      color: "#9D9D9D",
                      mr: 0.5,
                    }}
                  />
                  <Label>
                    {videoViews} {t("card.views")}
                  </Label>
                </IconWrapper>
                {candidateId && (
                  <IconWrapper>
                    <StarRounded
                      sx={{
                        fontSize: "14px",
                        color: "#9D9D9D",
                        mr: 0.5,
                      }}
                    />
                    <Label>
                      {saves} {t("card.saves")}
                    </Label>
                  </IconWrapper>
                )}
              </IconsWrapper>
            </CardVideoWrapper>
            {isAuthenticated && (
              <ButtonsWrapper>
                <LikeButton
                  loading={addVideoLikeLoading || deleteVideoLikeLoading}
                  onClick={handleLikeUserCard}
                  isCandidate={!!user?.candidateId}
                  likedFromMe={likedFromMe}
                >
                  {likedFromMe ? (
                    <>
                      <ThumbUpRounded sx={{ fontSize: "14px", mr: 1 }} />
                      {t("card.like")}
                    </>
                  ) : (
                    <>
                      <ThumbUpRounded sx={{ fontSize: "14px", mr: 1 }} />
                      {t("card.like")}
                    </>
                  )}
                </LikeButton>
                {!!downloadCV && (
                  <Button
                    onClick={() => {
                      if (!email) {
                        setOpenUnlockModal(true);
                      } else {
                        handlePrint();
                      }
                    }}
                    className="capitalize flex-1 bg-[#EEF3FC] text-[#487BD9] rounded-[10px] mr-1"
                  >
                    {t("download_application")}
                  </Button>
                )}
                <MoreMenu
                  isMyFavorite={isMyFavorite}
                  candidateId={candidateId}
                  businessId={businessId}
                  businessUserId={businessUserId}
                  handleAddUserToFavorites={handleAddUserToFavorites}
                  boughtFromCurrentUser={boughtFromCurrentUser}
                  email={email}
                  phoneNumber={phoneNumber}
                  downloadCV={downloadCV}
                  unSaveProfile={() =>
                    deleteUserToFavorites({ favoriteUserId: candidateUserId })
                  }
                />
              </ButtonsWrapper>
            )}
            <UnsaveProfileModal
              open={openUnsaveProfile}
              setOpen={setOpenUnsaveProfile}
              unSaveProfile={() =>
                deleteUserToFavorites({ favoriteUserId: candidateUserId })
              }
            />
          </MainWrapper>
        )}
        {isAuthenticated && (
          <UnlockFieldModal
            isProfile={false}
            openUnlockModal={openUnlockModal}
            setOpenUnlockModal={setOpenUnlockModal}
            candidateId={candidateId}
            isCV={downloadingCV}
            candidateUserId={candidateUserId}
          />
        )}
      </>
    );
  }
);

export default Card;
